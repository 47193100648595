<template>
  <div>
    <div
      variant="light"
      class="justify-content-end container alert alert-white alert-shadow"
      style="padding-top: 10px"
    >
      <b-row>
        <b-col>
          <b-form-group label="Tên dịch vụ (*)">
            <b-form-select
                    class="form-control datatable-input"
                    data-col-index="6"
                    v-model="params.service"
                    :options="option.service"
            >
              <option :value="null">-- Chọn dịch vụ --</option>
            </b-form-select>
          </b-form-group>
        </b-col>
          <b-col>
              <b-form-group label="Tên ngân hàng (*)">
                  <b-form-select
                          class="form-control datatable-input"
                          data-col-index="6"
                          v-model="params.bankCode"
                          :options="option.bank"
                  >
                      <option :value="null">-- Chọn ngân hàng --</option>
                  </b-form-select>
              </b-form-group>
          </b-col>
      </b-row>
      <b-row>
          <b-col v-if="params.service === 'TRANSFER_BANK' && params.bankCode !== null">
              <b-form-group label="Loại">
                  <b-form-select
                          v-model="params.type"
                          :options="option.type"
                  >
                      <option :value="null">-- Chọn loại --</option>
                  </b-form-select>
              </b-form-group>
              <b-form-group label="Số thẻ" v-if="this.params.type === 'CARD'">
                  <b-form-input
                          v-model="params.value"
                          placeholder="Nhập số thẻ"
                  ></b-form-input>
              </b-form-group>
              <b-form-group label="Số tài khoản" v-else-if="this.params.type === 'ACCOUNT'">
                  <b-form-input
                          v-model="params.value"
                          placeholder="Nhập số tài khoản"
                  ></b-form-input>
              </b-form-group>
          </b-col>
          <b-col v-else-if="params.service === 'WITH_DRAW' && params.bankCode !== null">
              <b-form-group label="Số điện thoại ví">
                  <b-form-input
                          v-model="params.value"
                          placeholder="Số điện thoại..."
                  ></b-form-input>
              </b-form-group>
          </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Lý do (nếu có)">
            <b-form-textarea
              id="textarea"
              v-model="params.reason"
              placeholder="Mô tả lý do"
              rows="5"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="card-footer" style="text-align: right">
        <button class="btn btn-primary mr-2" @click="store()">
          Lưu
        </button>
        <button class="btn btn-secondary mr-2" @click="showList()">
          Thoát
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '@/core/mixins/helper';
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
const BlackListRepository = RepositoryFactory.get("blackList");
import BankConfig from "@/core/config/bank.config";
import { mapGetters } from "vuex";

Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      params: {
        service: null,
        bankCode: null,
        type: null,
        phone: null,
        card_no: null,
        reason: null,
        value: null,
      },
      option: {
        service: [
          { value: "TRANSFER_BANK", text: "Rút tiền qua chuyển khoản" },
          { value: "WITH_DRAW", text: "Rút tiền qua liên kết trực tiếp" },
        ],
        bank: [],
        type: [
          { value: "CARD", text: "Số thẻ" },
          { value: "ACCOUNT", text: "Số tài khoản" },
        ],
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thêm vào black list", route: "blackListAdd" },
    ]);
  },
  methods: {
    store() {
      if (
        this.params.service === null
      ) {
        this.notifyAlert(
          "warn",
          "Tên dịch vụ không được bỏ trống",
        );
        return;
      }
        if (
            this.params.bankCode === null
        ) {
            this.notifyAlert(
                "warn",
                "Tên ngân hàng không được bỏ trống",
            );
            return;
        }
        if (
            this.params.service === "TRANSFER_BANK" && this.params.type === null
        ) {
            this.notifyAlert(
                "warn",
                "Loại không được bỏ trống",
            );
            return;
        }
        if (
            this.params.value === null
        ) {
            let valueText = null;

            if (this.params.type === "CARD") {
                valueText = "Số thẻ";
            } else if (this.params.type === "ACCOUNT") {
                valueText = "Số tài khoản";
            }
            if (this.params.service === 'WITH_DRAW') {
                valueText = "Số điện thoại";
            }
            this.notifyAlert(
                "warn",
                "Vui lòng nhập thông tin "+valueText,
            );
            return;
        }
      if (this.params.service === 'TRANSFER_BANK') {
          if (this.params.type === null) {
              this.notifyAlert("warn", "Khi chọn dịch vụ chuyển tiền về ngân hàng qua chuyển khoản, mục loại không được bỏ trống");
              return;
          }
      }
      if (this.params.service === 'WITH_DRAW') {
        if (this.params.value === null) {
          this.notifyAlert("warn", "Số điện thoại không được bỏ trống");
          return;
        }
        if (!helper.methods.is_phone(this.params.value)) {
          this.notifyAlert("warn", "Không phải số điện thoại, kiểm tra lại");
          return;
        }
      }
      let params = {
          'service':this.params.service+"_"+this.params.bankCode,
          'type':this.params.type,
          'value':this.params.value,
          'reason':this.params.reason,
          'created_by': this.currentUserPersonalInfo.email,
      };
      this.$bus.$emit("show-loading", true);

      BlackListRepository.add(params)
        .then((response) => {
          if (response.data.error_code == 0) {
            this.notifyAlert("success", 'Thêm tài khoản vào black list thành công');
            this.$router.push("/account/black-list");
          } else {
            this.notifyAlert("warn", response.data.message);
            this.$bus.$emit("show-loading", false);
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
      this.$bus.$emit("show-loading", false);
    },
    showList() {
      this.$router.push("/account/black-list");
    },
      fillBankListTransfer($bankType) {
          var listBank = [];
          this.params.bankCode = null;
          if ($bankType === 'TRANSFER_BANK') {
              listBank = BankConfig.getBankTransfer();
          } else if ($bankType === 'WITH_DRAW') {
              listBank = BankConfig.getBankLink();
          }
          let bank = [];
          listBank.forEach(function (item) {
              bank.push({value: item, text: item})
          });
          this.option.bank = bank;
      }
  },
    watch: {
        'params.service': function (newVal) {
            if (newVal === 'TRANSFER_BANK') {
                this.fillBankListTransfer(newVal);
            } else if (newVal === 'WITH_DRAW') {
                this.fillBankListTransfer(newVal);
            } else {
                this.option.bank = [];
            }
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
    },
};
</script>
